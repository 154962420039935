import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import InfoBlock from "../../../../templates/info-block/infoBlock";
import InfoGrid from "../../../../templates/info-grid/infoGrid";
import LabledValue from "../../../../components/molecules/labled-value/labledValue";
import { InvoiceDetails } from "../../../../app/data/invoice/models";
import { UserState } from "../../../../slices";
import { userSelector } from "../../../../slices/user/userSlice";
import { UserUtils } from "../../../../app/data/user/userUtils";
import "./invoiceDetailsInfo.scss";
import { freightStatusSelector } from "../../../../slices/exact-freight/freightStatusSlice";

export interface InvoiceDetailsInfoProps extends InvoiceDetails {}

const InvoiceDetailsInfo: React.FC<InvoiceDetailsInfoProps> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const freightStatusState = useSelector(freightStatusSelector);

  const getContactPhoneComponent = (phone: string | undefined | null) => {
    return (
      <span className="xgs-info-block__phone">
        <FontAwesomeIcon icon={phone ? Icons.faPhone : Icons.faPhoneSlash} size="lg" className="chart-icon" />
        {phone ? phone.formatPhone() : ""}
      </span>
    );
  };

  return (
    <InfoGrid className="xgs-invoice-details-grid">
      {props.fullAccess !== false && (
        <>
          <InfoBlock title="Invoice summary">
            <LabledValue label="Invoice date:" value={props.invoice?.dateInvoice.toUiDateFormat()} labelFixWidth />
            <LabledValue label="Invoice number:" value={props.invoice?.invoiceNumber} labelFixWidth />
            <LabledValue label="BOL:" value={props.invoice?.bolNumber} labelFixWidth />
            <LabledValue label="PO number:" value={props.invoice?.poNumber} labelFixWidth />
            <LabledValue label="Type of bill:" value={props.typeOfBill} labelFixWidth optional />
          </InfoBlock>
        </>
      )}
      {freightStatusState.details && (
        <>
          <InfoBlock className="xgs-invoice-details-grid__freight" title="Freight Status">
            <LabledValue
              label="Inbound Trailer :"
              values={[
                freightStatusState.details.inboundTrailer.trailerNumber,
                freightStatusState.details?.inboundTrailer.inboundDate?.toUiDateTimeFormat()
              ]}
              labelFixWidth
            />
            <LabledValue
              label="Loaded:"
              value={freightStatusState.details?.loaded?.toUiDateTimeFormat()}
              labelFixWidth
            />
            <LabledValue
              label="Dispatched:"
              value={freightStatusState.details?.dispatched?.toUiDateTimeFormat()}
              labelFixWidth
            />
            <LabledValue
              label="Arrived:"
              value={freightStatusState.details?.arrived?.toUiDateTimeFormat()}
              labelFixWidth
            />{" "}
            <LabledValue
              label="Unloaded:"
              value={freightStatusState.details?.unloaded?.toUiDateTimeFormat()}
              labelFixWidth
            />
            <LabledValue
              label="Closed:"
              value={freightStatusState.details?.closed?.toUiDateTimeFormat()}
              labelFixWidth
            />
          </InfoBlock>
          <InfoBlock title="Appointment status">
            <LabledValue
              label="Appointment Name:"
              value={freightStatusState.details?.appointment.appointmentName}
              labelFixWidth
            />
            <LabledValue
              label="Appointment Required:"
              value={freightStatusState.details?.appointment.appointmentRequired ? "Yes" : "No"}
              labelFixWidth
            />
            <LabledValue
              label="Allow Early Delivery:"
              value={freightStatusState.details?.appointment.allowEarlyDelivery ? "Yes" : "No"}
              labelFixWidth
            />
            <LabledValue
              label="Confirmed Date:"
              value={freightStatusState.details?.appointment.confirmedDate?.toUiDateFormat()}
              labelFixWidth
            />
            <LabledValue label="Early:" value={freightStatusState.details?.appointment.earlyTime?.toUiTimeFormat()} labelFixWidth />
            <LabledValue label="Late:" value={freightStatusState.details?.appointment.lateTime?.toUiTimeFormat()} labelFixWidth />
          </InfoBlock>
          <InfoBlock title="Carrier & Logistics">
            <LabledValue
              label="Load Manifest:"
              value={freightStatusState.details?.loadManifest}
              labelFixWidth
            />{" "}
            <LabledValue
              label="Trailer:"
              value={freightStatusState.details?.trailer}
              labelFixWidth
            />
            <LabledValue
              label="Carrier:"
              value={freightStatusState.details?.carrier}
              labelFixWidth
            />
            {freightStatusState.details?.freightLocation?.address && <>
              <LabledValue
                label="Location:"  
                value={freightStatusState.details.freightLocation.address.city + ", " + freightStatusState.details.freightLocation.address.state}
                labelFixWidth
              />
              <LabledValue
                label="Arrived:"  
                value={freightStatusState.details.freightLocation.arrivedDate.toUiDateTimeFormat()}
                labelFixWidth
              />
            </>}
          </InfoBlock>
        </>
      )}
      <InfoBlock title="Origin" headerInfo={getContactPhoneComponent(props.origin?.phone)}>
        {props.origin && (
          <>
            <div className="common-text">{`${props.origin.id ?? ""} ${props.origin.name ?? ""}`}</div>
            <div className="common-text">
              {props.origin.address ?? ""}
              <br />
              {`
                ${props.origin.city ? props.origin.city + ", " : ""} 
                ${props.origin.state ? props.origin.state + " " : ""} 
                ${props.origin.zip ?? ""}
              `}
            </div>
          </>
        )}
      </InfoBlock>
      <InfoBlock title="Destination" headerInfo={getContactPhoneComponent(props.destination?.phone)}>
        {props.destination && (
          <>
            <div className="common-text">{`${props.destination.id ?? ""} ${props.destination.name ?? ""}`}</div>
            <div className="common-text">
              {props.destination.address ?? ""}
              <br />
              {`
                ${props.destination.city ? props.destination.city + ", " : ""} 
                ${props.destination.state ? props.destination.state + " " : ""} 
                ${props.destination.zip ?? ""}
              `}
            </div>
          </>
        )}
      </InfoBlock>
      {props.fullAccess !== false && (
        <>
          <InfoBlock
            title={`Payor # ${props.payor?.number}`}
            headerInfo={getContactPhoneComponent(props.payor?.phone)}
          >
            <div className="common-text pre-wrap">{props.payor?.info}</div>
          </InfoBlock>
          <InfoBlock
            title={`Shipper # ${props.shipper?.number}`}
            headerInfo={getContactPhoneComponent(props.shipper?.phone)}
          >
            <div className="common-text pre-wrap">{props.shipper?.info}</div>
          </InfoBlock>
          <InfoBlock
            title={`Consignee # ${props.consignee?.number}`}
            headerInfo={getContactPhoneComponent(props.consignee?.phone)}
          >
            <div className="common-text pre-wrap">{props.consignee?.info}</div>
          </InfoBlock>
          {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
            <InfoBlock title="Trailer & driver">
              <LabledValue label="Trailer:" value={props.trailer} labelFixWidth />
              <LabledValue
                label="Driver:"
                value={`${props.cheetahInfo?.driverName ? props.cheetahInfo.driverName : ""} ${
                  props.cheetahInfo?.driverNumber ? props.cheetahInfo.driverNumber : ""
                }`}
                labelFixWidth
              />
              {/* {isOutForDelivery() && props.trailer && <TrailerfreightLocation invoiceNumber={props.invoice?.invoiceNumber} />} */}
            </InfoBlock>
          )}
        </>
      )}
    </InfoGrid>
  );
};

export default InvoiceDetailsInfo;
