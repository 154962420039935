import { useState } from "react";
import { useDropzone } from "react-dropzone";
import React from "react";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import styles from "../../../styles/variables.scss";

interface Props {
  onChange: (value: File[]) => void;
  documents: File[];
  probillNumber: string;
  hasDocument: boolean;
}

export const PodInfoFileField: React.FC<Props> = (props) => {
  const MAX_SIZE = 1;
  const MAX_PHOTOS = 1;
  const [fileError, setFileError] = useState<string>("");
  const { getInputProps, getRootProps } = useDropzone({
    accept: { "application/pdf": [] },
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS,
    disabled: props.hasDocument,
    noDrag: true,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only files of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} document is allowed!`);
          }
        });
      }
      // BUG: files count can keep going up
      if (acceptedFiles.length === 0) return;
      props.onChange(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            deleted: false,
            comment: "",
            internalOnly: false
          })
        )
      );
    }
  });

  return (
    <div className="xgs-enter-pod__upload">
      <div {...getRootProps({ className: "xgs-enter-pod__upload__area" })}>
        <input {...getInputProps()} />
        {props.documents.length === 0 && !props.hasDocument && <span className="blue-link">Click or tap to upload</span>}
        {props.hasDocument && <span><XGSIcon icon={XGSIcons.faCheck} color={styles.green1}/> Document already added</span>}
        {props.documents.length > 0 && (
          <span className="common-text green">
            <XGSIcon
              icon={XGSIcons.faXmarkCircle}
              cursor={"pointer"}
              style={{ marginLeft: 5 }}
              size="xl"
              color={styles.blue1}
              onClick={(e) => {
                e.stopPropagation();
                props.onChange([]);
              }}
            />&nbsp;
            {props.documents[0].name}
          </span>
        )}
        {fileError && <div>{fileError}</div>}
      </div>
    </div>
  );
};
