import moment from "moment";
import { validationMessages } from "../common/validationMessages";
import DeliveryStatus from "../tracking/DeliveryStatus";
import * as Yup from "yup";
import { FreightException } from "../freight-exception/models";
import { InvoiceDetailsItem } from "../invoice/models";
import { XGSSelectOption } from "../../../components/xgs-select/xgsSelect";
import { ServiceCenterModel } from "../tracking/ShipmentDetailsModel";

export interface ExactFreightModel {
  shipperName: string;
  distributor: string;
  consigneeName: string;
  probillNumber: string;
  probillColor: string;
  billDate: string;
  status: DeliveryStatus;
  billDateColor: string;
  expDeliveryDate: string;
}

export enum AppointmentChangeReason {
  BOL = "Bol Held At Origin",
  CDE = "Cust Closed For Holiday",
  CDA = "Cust Delayed Appointment",
  CPD = "Cust Postponed Appt/Delvry",
  CED = "Cust Requested Early Delivery",
  CDC = "Cust Requested Redelivery",
  CCH = "Customer Closed For Holiday",
  PDD = "Delayed From Previous Stop",
  DMD = "Destination Missed Delivery",
  DDA = "Destination S/C Damaged",
  DDC = "Destination S/C Missed Appt.",
  DDB = "Destination S/C Shortage",
  DWD = "Destination Weather Delay",
  LHD = "Linehaul Delay",
  LBD = "Linehaul Equipment Delay",
  LHS = "Linehaul Shortage",
  LHW = "Linehaul Weather Delay",
  ODM = "Origin Delay - Misload",
  ODD = "Origin Delayed Departure",
  ODA = "Origin Delay-Out Of Route",
  SOE = "State Of Emergency"
}

export const getAppointmentChangeReasons = (): XGSSelectOption[] => {
  return Object.entries(AppointmentChangeReason).map(([label, value]) => ({
    value: label,
    label: value
  }))
}

export interface AppointmentDetails {
  probillNumber: string;
  shipperName: string;
  consigneeName: string;
  allowEarlyDelivery: boolean;
  appointmentRequired: boolean;
  confirmedByUser?: string;
  confirmedDate?: string;
  confirmedTime?: string;
  earlyTime?: string;
  lateTime?: string;
  reason: AppointmentChangeReason;
  appointmentName: string;
  appointmentDate: string;
}

export interface FreightStatusInformation {
  inboundTrailer: {
    trailerNumber: string;
    inboundDate: string;
  };
  loadManifest: string;
  loaded: string;
  dispatched: string;
  deliveryManifest: string;
  deliveryTrailer: string;
  freightExceptions: FreightException[];
  unloaded: string;
  trailer: string;
  carrier: string;
  closed: string;
  arrived: string;
  expectedEta: string;
  originalEta: string;
  appointment: AppointmentDetails;
  appointmentHistory: FreightAppointmentHistory[];
  probillItems: InvoiceDetailsItem[];
  freightLocation?: {
    arrivedDate: string;
    address: ServiceCenterModel
  }
}

export interface FreightAppointmentHistory {
  appointmentDate: string;
  earlyTime: string;
  lateTime: string;
  appointmentName: string;
  confirmedBy: string;
  changedBy: string;
  reason: AppointmentChangeReason;
}

export interface FreightAppointmentHistoryResponse {
  currentAppointment: AppointmentDetails;
  appointmentHistory: FreightAppointmentHistory;
}

export interface UpdateFreightAppointmentRequest {
  probillNumber: number;
  appointmentName: string;
  appointmentDate: string;
  lateTime: string;
  earlyTime: string;
  reason: string;
  eta?: string;
}

export const UpdateFreightAppointmentSchema = Yup.object({
  appointmentDate: Yup.string().required(validationMessages.required),
  earlyTime: Yup.string().required(validationMessages.required),
  lateTime: Yup.string()
    .required(validationMessages.required)
    .test("is-late-time-valid", "Late time can't be less than or the same as early time.", function (value) {
      const { lateTime, earlyTime } = this.parent;
      if (moment(earlyTime, "H:mm a").isSameOrAfter(moment(lateTime, "H:mm a"))) {
        return false;
      }
      return true;
    }),
  reason: Yup.string().test("is-reason-required", "Required. Appointment later than expected ETA.", function (value) {
    const { appointmentDate, eta } = this.parent;
    if (!eta) return true;
    if (moment(appointmentDate, "MM/DD/YYYY").isAfter(moment(eta, "YYYY-MM-DD"))) {
      return !!value;
    }
    return true;
  }),
  appointmentName: Yup.string().trim().max(20).required(validationMessages.required)
}).defined();

export enum FreightGroupType {
  HAS_EXCEPTIONS = "HAS_EXCEPTIONS"
}

export interface FreightSearchRequest {
  probillNumber?: number;
  from?: string;
  to?: string;
  statuses?: string[];
  freightGroup?: FreightGroupType;
  etaFrom?: string;
  etaTo?: string;
  masterbill?: number;
}
