import { combineReducers } from "redux";
import bolReducer from "./bol/bolSlice";
import BolState from "./bol/BolState";
import deliveryReceiptsReducer from "./delivery-receipts/deliveryReceiptsSlice";
import ExactFreightState from "./exact-freight/exactFreightState";
import filtersToggleReducer from "./filters-toggle/filtersToggleSlice";
import FiltersToggleState from "./filters-toggle/FiltersToggleState";
import forgotPasswordReducer from "./user/forgotPasswordSlice";
import ForgotPasswordState from "./user/ForgotPasswordState";
import freightAppointmentReducer from "./exact-freight/freightAppointmentSlice";
import FreightAppointmentState from "./exact-freight/freightAppointmentState";
import freightExceptionReducer from "./freight-exception/freightExceptionSlice";
import FreightExceptionState from "./freight-exception/freightExceptionState";
import freightStatusReducer from "./exact-freight/freightStatusSlice";
import FreightStatusState from "./exact-freight/freightStatusState";
import invoiceDetailsReducer from "./invoice/invoiceSlice";
import invoiceDocumentsReducer from "./invoice-documents/invoiceDocumentsSlice";
import InvoiceDocumentsState from "./invoice-documents/InvoiceDocumentsState";
import invoiceRecordsReducer from "./invoice-records/invoiceRecordsSlice";
import InvoiceRecordsState from "./invoice-records/InvoiceRecordsState";
import linehaulManifestReducer from "./linehaul-manifest/linehaulManifestSlice";
import LinehaulManifestState from "./linehaul-manifest/LinehaulManifestState";
import loadManifestReducer from "./load-manifest/loadManifestSlice";
import podReducer from "./pod-information/podSlice";
import ratesReducer from "./rates/ratesSlice";
import RatesState from "./rates/RatesState";
import recordsReducer from "./delivery-records/recordsSlice";
import RecordsState from "./delivery-records/RecordsState";
import resetPasswordReducer from "./reset-password/resetPasswordSlice";
import ResetPasswordState from "./reset-password/ResetPasswordState";
import requestRatesReducer from "./rates/requestRatesSlice";
import RequestRatesState from "./rates/RequestRatesState";
import rollDetailsReducer from "./roll-details/rollDetailsSlice";
import RollDetailsState from "./roll-details/rollDetailsState";
import searchFreightExceptionsReducer from "./freight-exception/searchFreightExceptionsSlice";
import serviceCentersReducer from "./service-centers/serviceCentersSlice";
import ServiceCentersState from "./service-centers/ServiceCentersState";
import shipmentDetailsReducer from "./shipment-details/shipmentDetailsSlice";
import ShipmentDetailsState from "./shipment-details/ShipmentDetailsState";
import trackShipmentReducer from "./track-shipment/trackShipmentSlice";
import TrackShipmentState from "./track-shipment/TrackShipmentState";
import trailerLocationReducer from "./trailer-location/trailerLocationSlice";
import TrailerLocationState from "./trailer-location/TrailerLocationState";
import UserProfile from "../app/data/user/UserProfile";
import usersReducer from "./user/userSlice";
import { DeliveryReceiptsState } from "./delivery-receipts/DeliveryRecieptsState";
import { exactFreightReducer } from "./exact-freight/exactFreightSlice";
import { InvoiceDetails } from "../app/data/invoice/models";
import { LoadManifestState } from "./load-manifest/loadManifestState";
import { PodState } from "./pod-information/PodState";
import { SearchFreightExceptionsState } from "./freight-exception/searchFreightExceptionsState";

export interface UserState {
  loggedIn: boolean;
  loginProcess: boolean;
  logoutProcess: boolean;
  loginFailed: boolean;
  loginFirstAttemptFinished: boolean;
  profile: UserProfile | null | undefined;
  failClass: string | null | undefined;
  failReason: string | null | undefined;
  failUsername: string | null | undefined;
  getCurrentUser_is_Started: boolean;
  getCurrentUser_is_Succeed: boolean;
  getCurrentUser_is_Failed: boolean;
  status: string | undefined;
  showMobileSideMenu: boolean;
  subAccounts: any;
  activeSubAccount?: any;
}

export interface InvoiceDetailsState extends BaseState {
  invoiceDetails: InvoiceDetails | null | undefined;
  needToReload: boolean | null | undefined;
}

export interface BaseState {
  loading: boolean;
  loadingFailed: boolean;
  loadingError?: string | null;
  loaded: boolean;
}

export interface InfiniteScrollBaseState extends BaseState {
  loadingPortion: boolean;
  loadedAll: boolean;
}
export interface IState {
  bol: BolState;
  deliveryReceipts: DeliveryReceiptsState;
  exactFreight: ExactFreightState;
  filtersToggle: FiltersToggleState;
  forgotPassword: ForgotPasswordState;
  freightAppointment: FreightAppointmentState;
  freightException: FreightExceptionState;
  freightStatus: FreightStatusState;
  invoiceDetails: InvoiceDetailsState;
  invoiceDocuments: InvoiceDocumentsState;
  invoiceRecords: InvoiceRecordsState;
  linehaulManifest: LinehaulManifestState;
  loadManifest: LoadManifestState;
  pod: PodState;
  rates: RatesState;
  records: RecordsState;
  resetPassword: ResetPasswordState;
  requestRates: RequestRatesState;
  rollDetails: RollDetailsState;
  searchFreightExceptions: SearchFreightExceptionsState;
  serviceCenters: ServiceCentersState;
  shipmentDetails: ShipmentDetailsState;
  trackShipment: TrackShipmentState;
  trailerLocation: TrailerLocationState;
  user: UserState;
}

const appReducer = combineReducers({
  bol: bolReducer,
  deliveryReceipts: deliveryReceiptsReducer,
  exactFreight: exactFreightReducer,
  filtersToggle: filtersToggleReducer,
  forgotPassword: forgotPasswordReducer,
  freightAppointment: freightAppointmentReducer,
  freightException: freightExceptionReducer,
  freightStatus: freightStatusReducer,
  invoiceDetails: invoiceDetailsReducer,
  invoiceDocuments: invoiceDocumentsReducer,
  invoiceRecords: invoiceRecordsReducer,
  linehaulManifest: linehaulManifestReducer,
  loadManifest: loadManifestReducer,
  pod: podReducer,
  rates: ratesReducer,
  records: recordsReducer,
  resetPassword: resetPasswordReducer,
  requestRates: requestRatesReducer,
  rollDetails: rollDetailsReducer,
  searchFreightExceptions: searchFreightExceptionsReducer,
  serviceCenters: serviceCentersReducer,
  shipmentDetails: shipmentDetailsReducer,
  trackShipment: trackShipmentReducer,
  trailerLocation: trailerLocationReducer,
  user: usersReducer
});

const rootReducer = (state: any, action: any) => {
  return appReducer(action.type === "FULL_RESET" ? { user: state.user } : state, action);
};

export default rootReducer;
