import React, { useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { xgsRoutes } from "../../../app/route/RoutesConfig";
import { trackShipmentSelector } from "../../../slices/track-shipment/trackShipmentSlice";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { EnterPODInformationModel } from "../../../app/data/pod/models";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import Button, { ButtonSizes, ButtonThemes } from "../../../components/button/button";
import "./enterPodInformation.scss";
import { EnterPODInformationSchema } from "../../../app/data/pod/models";
import { addPods, podSelector } from "../../../slices/pod-information/podSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { debounce, cloneDeep } from "lodash";
import { PodSearchParams } from "../route";
import { EnterPodRow } from "./enterPodRow";
import { getPodFormValidateDelay, getPodFromFillDuplicatesDelay } from "./helpers";
import { MaxPodRowsSelectable } from "../getPodInformationColumns";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";

const initialFormValues: EnterPODInformationModel[] = new Array(3).fill(0).map((_, idx) => ({
  probillNumber: "",
  signature: "",
  deliveryDate: moment().format("MM/DD/YYYY"),
  deliveryTime: "",
  lateCode: "",
  willCall: false,
  comments: "",
  duplicate: false,
  probillIsValid: true
}));

export const EnterPODInformation: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const shipmentsState = useSelector(trackShipmentSelector);
  const dispatch = useAppDispatch();
  const [documents, setDocument] = useState<Map<number, File[]>>(new Map());
  const formRef = useRef<FormikProps<{ pods: EnterPODInformationModel[] }>>(null);
  const podState = useSelector(podSelector);

  const selectedInvoiceNumbers: number[] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const invoices = params.get(PodSearchParams.selectedInvoices)?.split(",").map(Number);
    return invoices || [];
  }, [location]);

  const allDuplicates = !!new URLSearchParams(location.search).get(PodSearchParams.allDuplicates);

  const initialPodFormState: EnterPODInformationModel[] | null = useMemo(() => {
    return selectedInvoiceNumbers.length
      ? selectedInvoiceNumbers.map((invoiceNumber, idx) => {
          return {
            probillNumber: String(invoiceNumber),
            deliveryDate: moment().format("MM/DD/YYYY"),
            deliveryTime: "",
            lateCode: "",
            willCall: false,
            signature: "",
            comments: "",
            duplicate: idx > 0 && allDuplicates
          };
        })
      : initialFormValues;
  }, [selectedInvoiceNumbers, allDuplicates]);

  const fillOutDuplicates = useMemo(() => {
    const __fillOutDuplicates = () => {
      if (!formRef.current) return;
      var values = cloneDeep(formRef.current.values.pods);
      for (let i = 0; i < values.length; i++) {
        const pod = values[i];
        if (pod.duplicate) {
          values[i] = { ...values[i - 1], duplicate: true, probillNumber: pod.probillNumber, eta: pod.eta };
        }
      }
      formRef.current.setValues({ pods: values });
    };
    return debounce(__fillOutDuplicates, getPodFromFillDuplicatesDelay(formRef.current?.values.pods.length || 1));
  }, []);

  const onSubmit = (values: EnterPODInformationModel[]) => {
    const files: File[] = [];
    const parameters: Array<{ probillNumber: number }> = [];
    values.forEach((value, idx) => {
      const acceptedFiles = documents.get(idx);
      if (!acceptedFiles || !acceptedFiles.length) return;
      parameters.push({ probillNumber: Number.parseInt(value.probillNumber)});
      files.push(acceptedFiles[0]);
    })
    const preparedData = {
      parameters: parameters,
      podList: values
    };

    const fd = new FormData();
    fd.append("data", JSON.stringify(preparedData));
    for (let file of files) {
      fd.append("files", file);
    }
    dispatch(
      addPods(fd,
        () => {
          toast.info("Added " + (selectedInvoiceNumbers.length > 1 ? "PODs" : "POD"));
          navigate(xgsRoutes.shipments.podInformation);
        },
        (message?: any) => {
          toast.error(message ? message : "Something went wrong");
        }
      )
    );
  };

  const directEntry = useMemo(() => {
    return !new URLSearchParams(location.search).get(PodSearchParams.selectedInvoices);
  }, [location.search]);

  return (
    <ContentContainer title="Enter POD Information" isLoading={shipmentsState.loading}>
      {initialPodFormState && (
        <div>
          <Formik
            enableReinitialize
            validateOnChange={false}
            innerRef={formRef}
            validationSchema={EnterPODInformationSchema as any}
            onSubmit={({ pods }) => onSubmit(pods)}
            initialValues={{ pods: initialPodFormState }}
          >
            {(props) => {
              let validateForm = debounce(props.validateForm, getPodFormValidateDelay(props.values.pods.length));
              return (
                <Form>
                  <div>
                    <FieldArray name="pods">
                      {(arrayProps) => (
                        <>
                          <div className="xgs-enter-pod__form__controls">
                            <Button
                              className="xgs-enter-pod__form__controls__submit"
                              spinner={podState.requestStarted}
                              style={{ marginBottom: 16 }}
                              theme={ButtonThemes.blue}
                              type="submit"
                            >
                              Submit
                            </Button>
                            {directEntry && <div className="xgs-enter-pod__form__controls__list">
                              <Button
                                disabled={props.values.pods.length >= MaxPodRowsSelectable}
                                size={ButtonSizes.small}
                                onClick={() => arrayProps.push({ ...initialFormValues[0] })}
                                theme={ButtonThemes.blue}
                                type="button"
                              >
                                <XGSIcon color="white" icon={XGSIcons.faPlus} />
                                <span className="xgs-mobile-only">Row</span>
                                <span className="xgs-desktop-only">Add row</span>
                              </Button>
                              <Button
                                disabled={props.values.pods.length <= 1}
                                theme={ButtonThemes.gray}
                                onClick={() => (props.values.pods.length > 1 ? arrayProps.pop() : "")}
                                size={ButtonSizes.small}
                                type="button"
                              >
                                <XGSIcon icon={XGSIcons.faMinus} />
                                <span className="xgs-mobile-only">Row</span>
                                <span className="xgs-desktop-only">Remove</span>
                              </Button>
                            </div>}
                          </div>
                          <div className="xgs-enter-pod__form">
                            {props.values.pods.map((item, idx) => {
                              return (
                                <EnterPodRow
                                  onBlur={props.handleBlur}
                                  onChange={props.handleChange}
                                  directEntry={directEntry}
                                  setFieldValue={(field: string, val: any) => {
                                    props.setFieldValue(field, val);
                                    validateForm();
                                    fillOutDuplicates();
                                  }}
                                  onChangeDocument={(documents: File[]) => {
                                    setDocument(state => {
                                      state.set(idx, documents);
                                      return cloneDeep(state);
                                    });
                                  }}
                                  documents={documents.get(idx) || []}
                                  podRow={item}
                                  idx={idx}
                                  key={"pod-row+" + idx}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </ContentContainer>
  );
};
