import React from "react";
import { AppointmentChangeReason } from "../../app/data/exact-freight/models";

export const getAppointmentHistoryColumns = (size?: "small" | "large") => {
  return [
    {
      Header: "Appt Date",
      accessor: "appointmentDate",
      width: 100,
      Cell: (props: any) => {
        return <>{props.value.toUiDateFormat()}</>
      }
    },
    {
      Header: "Early",
      accessor: "earlyTime",
      width: 80,
      Cell: (cellProps: any) => (
        <>{cellProps.value.toUiTimeFormat()}</>
      )
    },
    {
      Header: "Late",
      accessor: "lateTime",
      width: 80,
      Cell: (cellProps: any) => (
        <>{cellProps.value.toUiTimeFormat()}</>
      )
    },
    {
      Header: "Appt Name",
      accessor: "appointmentName",
      width: 150
    },
    {
      Header: "Confirmed By",
      accessor: "confirmedBy"
    },
    ...(size === "small" ? [] : [{
      Header: "Changed By",
      accessor: "changedBy"
    }]),
    {
      Header: "Reason",
      accessor: "reason",
      width: size === "small" ? 220 : 150,
      Cell: (cellProps: any) => {
        const key = cellProps.value as keyof typeof AppointmentChangeReason;
        return <>{AppointmentChangeReason[key] || cellProps.value}</>;
      }
    }
  ];
};
