import axios, { AxiosResponse } from "axios";
import api, { ApiResponse } from "./../api";
import {
  ForgotPasswordRequest,
  ResetPasswordRequestModel,
  TokenRequestModel,
  TokenResponseModel
} from "./requestModels";

class UserService {
  private static instance: UserService;
  private constructor() {}

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public sendForgotPasswordRequest = async (request: ForgotPasswordRequest): Promise<ApiResponse<any>> => {
    return await api.post("/users/forgot-password-request", request);
  };

  public getAuthToken = async (
    request: TokenRequestModel
  ): Promise<AxiosResponse<TokenResponseModel>> => {
    const params = new URLSearchParams(request).toString();
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.post(process.env.REACT_APP_TOKEN_ENDPOINT || "", params, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
    });
  };

  public checkResetPasswordToken = async (
    resetToken: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/users/byToken?token=${resetToken}`);
  };

  public getCurrent = async (): Promise<ApiResponse<any>> => {
    return await api.get(`/users/current`);
  };

  public logout = async (): Promise<AxiosResponse<any>> => {
    const params = new URLSearchParams();
    params.append("post_logout_redirect_uri", process.env.REACT_APP_DOMAIN || "");
    params.append("id_token_hint", localStorage.getItem("xgs-driver-id-token") || "");
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.get(process.env.REACT_APP_LOGOUT_ENDPOINT || "", { params });
  };

  public submitResetPassword = async (
    request: ResetPasswordRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/reset-password", request);
  };
};

export default UserService;
