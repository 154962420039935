import React, { useEffect, useState } from "react";
import { EnterPODInformationModel, getPodTimeOptions, lateCodesOptions } from "../../../app/data/pod/models";
import LabeledDateInput from "../../../components/form/date/xgsFormDate";
import { Field } from "formik";
import moment from "moment";
import LabeledInput, { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import XGSFormSelect from "../../../components/form/select/xgsFormSelect";
import XGSMaskInput from "../../../components/form/maskinput/xgsMaskInput";
import InvoiceService from "../../../app/data/invoice/invoiceService";
import { PodInfoFileField } from "./podInfoFileField";

interface EnterPodRowformikProps {
  idx: number;
  podRow: EnterPODInformationModel;
  setFieldValue: any;
  directEntry: boolean;
  onBlur: any;
  onChange: any;
  documents: File[];
  onChangeDocument: any;
}

const invoiceService = InvoiceService.getInstance();

export const EnterPodRow: React.FC<EnterPodRowformikProps> = (({setFieldValue, podRow, idx, directEntry, ...props}) => {
  const [isLoadingValidation, setIsLoadingValidation] = useState<boolean>();
  const [hasDocument, setHasDocument] = useState<boolean>();

  useEffect(() => {
    if (podRow.probillNumber.length > 6) {
      setIsLoadingValidation(true);
      invoiceService.probillEtaDocumentCheck(Number(podRow.probillNumber)).then(response => {
        setIsLoadingValidation(false);
        if (response.ok()) {
          setFieldValue(`pods.${idx}.eta`, response.data.eta.expectedEta)
          setFieldValue(`pods.${idx}.probillIsValid`, true);
          setHasDocument(response.data.hasDocument);
        } else {
          setFieldValue(`pods.${idx}.eta`, null);
          setFieldValue(`pods.${idx}.probillIsValid`, false);
          console.log(`eta fetch failed for probill: ${podRow.probillNumber}, ` + response.getError())

        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podRow.probillNumber, directEntry, idx])

  return (
    <div className={`xgs-enter-pod__form__row`} title={directEntry ? "" : `${idx+1}. ${podRow.probillNumber}`}>
        <span className="xgs-enter-pod__form__row__serial">{idx+1}.</span>
        <XGSMaskInput
          label="Probill:"
          name={`pods.${idx}.probillNumber`}
          disabled={!directEntry}
          required={directEntry}
          labelMode={LabelModes.column}
          format="########"
          allowNegative={false}
          onValueChange={(val) => {
            setFieldValue(`pods.${idx}.probillNumber`, val);
          }}
          onBlur={props.onBlur}
          className="xgs-enter-pod__form__row__field xgs-enter-pod__form__row__field__probill"
          value={podRow.probillNumber}
          isLoading={isLoadingValidation}
        />
        <LabeledInput className="xgs-enter-pod__form__row__field xgs-enter-pod__form__row__field--small xgs-form__checkbox" label="Duplicate:" labelMode={LabelModes.column}>
          <Field
            disabled={idx === 0}
            onChange={(e: any) => {
              setFieldValue(`pods.${idx}.duplicate`, e.target.checked);
            }} 
            type="checkbox"
            name={`pods.${idx}.duplicate`}
            onBlur={props.onBlur}
          />
        </LabeledInput>
        <LabeledDateInput
          name={`pods.${idx}.deliveryDate`}
          disabled={podRow.duplicate}
          label="Del. Date:"
          onDateChange={(v) => {
            setFieldValue(`pods.${idx}.deliveryDate`, v);
          }}
          onBlur={props.onBlur}
          onChange={props.onChange}
          required={true}
          labelMode={LabelModes.column}
          disableWeekends={true}
          className="xgs-enter-pod__form__row__field"
          maxDate={moment().toDate()}
        />
        <XGSFormSelect
          isSearchable={true}
          name={`pods.${idx}.deliveryTime`}
          label={"Time(EST):"}
          options={getPodTimeOptions()}
          menuPlacement="auto"
          onValueChange={(option) => {
            setFieldValue(`pods.${idx}.deliveryTime`, option?.value);
          }}
          required={true}
          labelMode={LabelModes.column}
          value={getPodTimeOptions().find(({ value }) => podRow.deliveryTime === value) || null}
          onBlur={props.onBlur}
          className="xgs-enter-pod__form__row__field"
          disabled={podRow.duplicate}
        />
        <LabeledInput 
          className="xgs-enter-pod__form__row__field xgs-enter-pod__form__row__field--small xgs-form__checkbox"
          label="Will Call:"
          labelMode={LabelModes.column}
        >
          <Field 
            onChange={(e: any) => {
              setFieldValue(`pods.${idx}.willCall`, e.target.checked);
            }}
            disabled={podRow.duplicate} 
            onBlur={props.onBlur}
            type="checkbox" 
            name={`pods.${idx}.willCall`} 
          />
        </LabeledInput>
        <XGSFormSelect
          isSearchable={false}
          name={`pods.${idx}.lateCode`}
          label={"Late Code:"}
          options={lateCodesOptions}
          required={podRow.eta ? moment(podRow.deliveryDate).isAfter(podRow.eta) : false}
          menuPlacement="auto"
          onBlur={props.onBlur}
          labelMode={LabelModes.column}
          onValueChange={(option) => {
            setFieldValue(`pods.${idx}.lateCode`, option?.value);
          }}
          className="xgs-enter-pod__form__row__field xgs-enter-pod__form__row__field--large"
          disabled={podRow.duplicate}
          value={lateCodesOptions.find(option => option.value === podRow.lateCode) || null}
        />
        <XGSFormInput
          name={`pods.${idx}.signature`}
          label={"Signature:"}
          maxLength={10}
          onChange={(e) => {
            setFieldValue(`pods.${idx}.signature`, e.currentTarget.value.replace(/[^a-zA-Z0-9]/g, ''));
          }}
          required={true}
          onBlur={props.onBlur}
          labelMode={LabelModes.column}
          className="xgs-enter-pod__form__row__field "
          disabled={podRow.duplicate}
        />
        <XGSFormInput
          name={`pods.${idx}.comments`}
          label={"Comments:"}
          labelMode={LabelModes.column}
          maxLength={30}
          onBlur={props.onBlur}
          onChange={(e) => {
            setFieldValue(`pods.${idx}.comments`, e.currentTarget.value.replace(/[^a-zA-Z0-9]/g, ''));
          }}
          className="xgs-enter-pod__form__row__field xgs-enter-pod__form__row__field--large"
          disabled={podRow.duplicate}
        />
        <LabeledInput className="xgs-enter-pod__upload-field" label="Document:" labelMode={LabelModes.column}>
          <PodInfoFileField
            probillNumber={podRow.probillNumber}
            documents={props.documents}
            onChange={(files) => props.onChangeDocument(files)}
            hasDocument={!!hasDocument}
          />
        </LabeledInput>
    </div>
  )
})