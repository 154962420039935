import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import LogRocket from "logrocket";
import { useAppDispatch } from "../../../hooks/storeHooks";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import XGSErrorMessage from "../../../components/error-message/errorMessage";
import { ForgotPasswordRequest, ForgotPasswordRequestSchema } from "../../../app/data/user/requestModels";
import ForgotPasswordState from "../../../slices/user/ForgotPasswordState";
import { forgotPasswordSelector, sendForgotPasswordRequest, resetForgotPassword } from "../../../slices/user/forgotPasswordSlice";
import { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../components/button/button";
import Loading from "../../../components/loading/loading";
import "./forgotPassword.scss";
import "../common.scss";

const initialValues: ForgotPasswordRequest = {
  login: ""
};

const ForgotPassword: React.FC<{}> = (props) => {
  const forgotPasswordState: ForgotPasswordState = useSelector(forgotPasswordSelector);
  const dispatch = useAppDispatch();

  const onSubmit = async (data: ForgotPasswordRequest) => {
    dispatch(sendForgotPasswordRequest(data));
  };

  useEffect(() => {
    dispatch(resetForgotPassword());
    if (process.env.REACT_APP_XGS_ENV === "production") {
      LogRocket.init("isggxn/xgs-customer-portal");
      window.addEventListener("unhandledrejection", e => {
        LogRocket.captureException(e.reason);
      });
    }

    return function cleanup() {
      dispatch(resetForgotPassword());
    };
  }, [dispatch]);

  return (
    <div className="xgs-login">
      <div className="xgs-login__wrapper">
        <div className="xgs-login__content">
          <div className="xgs-login__title">Forgot your password?</div>
          {forgotPasswordState.failed && (
            <XGSErrorMessage className="xgs-login-failed-message xgs-login-failed-message--top">
              {forgotPasswordState.failReason
                ? (<>{forgotPasswordState.failReason}</>)
                : (<>Something went wrong!</>)
              }
            </XGSErrorMessage>
          )}
          {forgotPasswordState.started && (
            <Loading isLoading={true} />
          )}
          {forgotPasswordState.succeed && (
            <div className="xgs-forgot-password__success">
              If this user exists, we have sent you a password reset email. Please check your inbox or spam folder.
            </div>
          )}
          {!forgotPasswordState.succeed && (
            <>
              <div className="xgs-forgot-password__notes">Enter your email address below and we'll send you password reset instructions.</div>
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={ForgotPasswordRequestSchema}
              >
                {(props: FormikProps<ForgotPasswordRequest>) => (
                  <Form className="form-items">
                    <XGSFormInput
                      type="text"
                      name="login"
                      label="Email:"
                      required={true}
                      requiredAsteriskDisabled={true}
                      labelMode={LabelModes.column}
                    />
                    <Button
                      theme={ButtonThemes.blue}
                      type="submit"
                      disabled={forgotPasswordState.started}
                      className="xgs-login__button"
                    >
                      Send Instructions
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          )}
          <div className="xgs-site__box__link">Go back to <a href="/" className="blue-link">Log In</a></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
