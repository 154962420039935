import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface LoginFormModel {
  username: string;
  password: string;
};

export const LoginFormSchema: Yup.ObjectSchema<LoginFormModel> = Yup.object({
  username: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .required(validationMessages.required),
  password: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .max(30, validationMessages.max30chars)
    .required(validationMessages.required)
}).defined();

export interface LoginRequest {
  email: string;
  password: string;
};

export interface ForgotPasswordRequest {
  login: string;
};

export const ForgotPasswordRequestSchema: Yup.ObjectSchema<ForgotPasswordRequest> = Yup.object({
  login: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .required(validationMessages.required)
}).defined();

export interface ResetPasswordRequestModel {
  token: string;
  password: string;
  confirmPassword: string | unknown;
};

export const ResetPasswordSchema: Yup.ObjectSchema<ResetPasswordRequestModel> = Yup.object(
  {
    token: Yup.string().required(),
    password: Yup.string()
      .matches(/(\w+|-+)/, validationMessages.notValid)
      .min(5, "The value must be more than 4 characters")
      .max(30, validationMessages.max30chars)
      .required(validationMessages.required),
    confirmPassword: Yup.string()
      .required(validationMessages.required)
      .test({
        name: "equalTo",
        exclusive: false,
        message: "Passwords do not match",
        params: {
          reference: Yup.ref("password"),
        },
        test(value: any) {
          return value === this.resolve(Yup.ref("password"));
        }
      })
  }
).defined();

export interface TokenRequestModel {
  [key: string]: string;
  grant_type: string;
  client_id: string;
  scope: string;
  redirect_uri: string;
  code: string;
};

export interface TokenResponseModel {
  access_token: string;
  refresh_token: string;
  id_token: string;
};
