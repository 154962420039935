import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  matchPath
} from "react-router-dom";
import { useSelector } from "react-redux";
import LogRocket from "logrocket";
import { useAppDispatch } from "../hooks/storeHooks";
import initExtensions from "../extensions/initExtensions";
import {
  getRouteConfig,
  publicRoutesComponents,
  xgsRoutes
} from "./route/RoutesConfig";
import Header from "../components/header/header";
import Menu from "../components/menu/menu";
import PageLoading from "../features/page-loading/pageLoading";
import XGSErrorMessage from "../components/error-message/errorMessage";
import PureBreadcrumbs from "../components/breadcrumbs/breadcrumbs";
import {
  getCurrentUser,
  userSelector
} from "../slices/user/userSlice";
import { UserState } from "../slices";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let appInited = false;

initExtensions();


if (process.env.REACT_APP_XGS_ENV === "production") {
  LogRocket.init("isggxn/xgs-agent-portal");
  window.addEventListener("unhandledrejection", e => {
    LogRocket.captureException(e.reason);
  });
}

const App: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
    if ((appInited && userState.loggedIn) ||
      publicRoutesComponents.find(publicRoute => matchPath(publicRoute, window.location.pathname)) ||
      !process.env.REACT_APP_AUTH_ENDPOINT  ||
      !process.env.REACT_APP_SSO_CLIENT_ID) return;
    const originHref = window.location.href;
    const originPathname = window.location.pathname;
    appInited = true;
    dispatch(getCurrentUser("", () => {}, (statusCode: number) => {
      if (statusCode === 401) {
        // store origin href only in case it isn't the root
        if (!localStorage.getItem("xgs-agent-origin-href") && originPathname && originPathname !== "/") localStorage.setItem("xgs-agent-origin-href", originHref);
        const redirectUrl = process.env.REACT_APP_DOMAIN + `/auth-callback?originUrl=${encodeURIComponent(originHref)}`;
        const authUrl = process.env.REACT_APP_AUTH_ENDPOINT + `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${redirectUrl}`;
        window.location.replace(authUrl);
      } else if (statusCode) {
        setError(true);
      }
    }));
  }, [dispatch, userState.loggedIn]);

  return (
    <div className="xgs-site">
      <BrowserRouter>
        <Header />
        <div>
          <Routes>
            {(publicRoutesComponents.length > 0) && publicRoutesComponents.map((route, i) => (
              <Route key={i} path={route.path} element={<route.component />} />
            ))}
          </Routes>
          {!userState.loginProcess &&
            !userState.logoutProcess &&
            !userState.getCurrentUser_is_Started &&
            userState.loginFirstAttemptFinished && (
              <>
                {userState.loggedIn && (
                  <div className="xgs-site__wrapper">
                    <Menu />
                    <div className="xgs-site__content">
                      <PureBreadcrumbs />
                      <Routes>
                        {getRouteConfig(userState.profile).map((route, i) => (
                          <Route key={i} path={route.path} element={<route.component />} />
                        ))}
                        <Route path="*" element={<Navigate to={xgsRoutes.home} />} />
                      </Routes>
                    </div>
                  </div>
                )}
              </>
            )}
          <Routes>
            {(userState.loginProcess || userState.logoutProcess || userState.getCurrentUser_is_Started) && (
              <Route path="*" element={<PageLoading isLoading={true} />} />
            )}
          </Routes>
        </div>
        {error && (
          <XGSErrorMessage className="block-center xgs-space__top__40">
            {userState.failReason || "Error. Please try again later."}
          </XGSErrorMessage>
        )}
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
