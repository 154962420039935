import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import UserService from "../../app/data/user/userService";
import { initialResetPasswordState } from "./ResetPasswordState";
import { ResetPasswordRequestModel  } from "../../app/data/user/requestModels";

const userService = UserService.getInstance();

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: initialResetPasswordState,
  reducers: {
    resetResetPasswordState: (state) => initialResetPasswordState,
    checkStarted: (state) => {
      state.checkStarted = true;
      state.checkFailed = false;
      state.checkSucceed = false;
      state.checkError = null;
    },
    checkSucceed: (state, { payload }) => {
      state.checkStarted = false;
      state.checkFailed = false;
      state.checkSucceed = true;
      state.checkError = null;
      state.email = payload.email;
    },
    checkFailed: (state, { payload }) => {
      state.checkStarted = false;
      state.checkFailed = true;
      state.checkSucceed = false;
      state.checkError = payload;
    },
    submitStarted: (state) => {
      state.submitStarted = true;
      state.submitFailed = false;
      state.submitSucceed = false;
      state.submitError = null;
    },
    submitSucceed: (state) => {
      state.submitStarted = false;
      state.submitFailed = false;
      state.submitSucceed = true;
      state.submitError = null;
    },
    submitFailed: (state, { payload }) => {
      state.submitStarted = false;
      state.submitFailed = true;
      state.submitSucceed = false;
      state.submitError = payload;
    }
  }
});

export const {
  resetResetPasswordState,
  checkStarted,
  checkSucceed,
  checkFailed,
  submitStarted,
  submitSucceed,
  submitFailed
} = resetPasswordSlice.actions;

export const resetPasswordSelector = (state: IState) => state.resetPassword;

export const checkResetPasswordToken = (
  token: string
): AppThunk => async (dispatch) => {
  dispatch(checkStarted());
  const response = await userService.checkResetPasswordToken(token);
  if (response.ok()) {
    dispatch(checkSucceed(response.data));
  } else {
    dispatch(checkFailed(response.getError && response.getError()));
  }
};

export const submitResetPassword = (
  request: ResetPasswordRequestModel
): AppThunk => async (dispatch) => {
  dispatch(submitStarted());
  const response = await userService.submitResetPassword(request);
  if (response.ok()) {
    dispatch(submitSucceed());
  } else {
    dispatch(submitFailed(response.getError && response.getError()));
  }
};

const resetPasswordReducer = resetPasswordSlice.reducer;
export default resetPasswordReducer;
