import React, { useEffect, useMemo, useState } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { useParams } from "react-router";
import { PathParams, ShipmentDetailsPath } from "./route";
import "./shipmentDetails.scss";
import { Tabs, TabPanel, Tab, TabList } from "react-tabs";
import InfoGrid from "../../../templates/info-grid/infoGrid";
import FitToWindowContainer from "../../../templates/fit-to-window-container/fitToWindowContainer";
import InfoBlock from "../../../templates/info-block/infoBlock";
import { useSelector } from "react-redux";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import {
  clearStatusHistory,
  getShipmentDetails,
  resetShipmentDetails,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import { useAppDispatch } from "../../../hooks/storeHooks";
import LabledValue from "../../../components/molecules/labled-value/labledValue";
import InvoiceDetailsInfo from "../../invoices/invoice-details/info/invoiceDetailsInfo";
import InvoiceDetailsTable from "../../invoices/invoice-details/table/invoiceDetailsTable";
import InvoiceDetailsDocuments from "../../invoices/invoice-details/documents/invoiceDetailsDocuments";
import ShipmentDetailsHeader from "./header/shipmentDetailsHeader";
import ShipmentComment from "./shipmentComment";
import ShipmentStatusHistory from "./shipmentStatusHistory";
import "./../../../components/tabs/tabs.scss";
import SteppedProgress from "../../../components/stepped-progress/steppedProgress";
import { AddShipmentComment } from "./addShipmentComment";
import { ChangeAppointment } from "../../appointment/changeAppointment";
import { AddFreightExceptions } from "../../exceptions/addFreightExceptions";
import {
  freightStatusSelector,
  getFreightStatusDetails,
  resetFreightStatusState
} from "../../../slices/exact-freight/freightStatusSlice";
import Table from "../../../components/table/table";
import { getAppointmentHistoryColumns } from "../../appointment/getAppointmentHistoryColumns";
import { getFreightExceptionHistoryColumns } from "../../exceptions/freight-exception-history/freightExceptionHistoryColumns";
import XGSErrorMessage from "../../../components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../components/button/button";
import { xgsRoutes } from "../../../app/route/RoutesConfig";
import { PodSearchParams } from "../../pod-information/route";
import { Link } from "react-router-dom";

const calcMinTableHeight = (length?: number) => {
  if (!length) return;
  if (length === 2) return 90;
  if (length === 3) return 135;
  if (length === 4) return 180;
  if (length === 5) return 225;
  return length > 5 ? 250 : 80;
};

export const ShipmentDetails: React.FC<{}> = (props) => {
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const freightDetailsState = useSelector(freightStatusSelector);
  const dispatch = useAppDispatch();
  const params = useParams() as unknown as PathParams;
  const [popupType, setPopupType] = useState<null | string>(null);
  const invoiceNumber = useMemo(() => {
    return parseInt(params[ShipmentDetailsPath.invoiceNumber], 10);
  }, [params]);

  useEffect(() => {
    if (invoiceNumber || shipmentDetailsState.needToReload || freightDetailsState.needsToReload) {
      dispatch(getShipmentDetails(invoiceNumber));
      dispatch(getFreightStatusDetails(invoiceNumber));
      return function cleanup() {
        dispatch(resetShipmentDetails());
        dispatch(clearStatusHistory());
        dispatch(resetFreightStatusState());
      };
    }
  }, [dispatch, invoiceNumber, shipmentDetailsState.needToReload, freightDetailsState.needsToReload]);

  let shouldShowPodButton = (shipmentDetailsState.shipment?.status.title === "AT DST SC" && !shipmentDetailsState.shipment.pod?.date) 

  return (
    <ContentContainer titleComponent={<ShipmentDetailsHeader onClickMenu={(type) => setPopupType(type)} />}>
      <ChangeAppointment invoiceNumber={invoiceNumber} show={popupType === "APPOINTMENT"} onClose={() => setPopupType(null)} />
      <AddFreightExceptions invoiceNumber={invoiceNumber} show={popupType === "EXCEPTION"} onClose={() => setPopupType(null)} />
      <FitToWindowContainer>
        {shipmentDetailsState.loadingFailed && (
          <XGSErrorMessage>{shipmentDetailsState.error}</XGSErrorMessage>
        )}
        {shipmentDetailsState.loaded && !shipmentDetailsState.shipment?.fullAccess && (
          <XGSErrorMessage>Access denied</XGSErrorMessage>
        )}
        {shipmentDetailsState.shipment && freightDetailsState.details && (
          <div className="xgs-shipment-details">
            <InfoGrid>
              {shipmentDetailsState?.shipment?.progress && (
                <div style={{ maxWidth: 896 }}>
                  <SteppedProgress shipmentDetails={shipmentDetailsState?.shipment} />
                </div>
              )}
              <div className="xgs-shipment-details__grid__column">
                <InfoBlock title="ETA">
                  <LabledValue
                    label="Original ETA:"
                    value={shipmentDetailsState?.shipment?.originalEta?.toUiDateFormat()}
                    labelFixWidth
                  />
                  <LabledValue
                    label="Expected ETA:"
                    value={shipmentDetailsState?.shipment?.expectedEta?.toUiDateFormat()}
                    labelFixWidth
                  />
                </InfoBlock>
              </div>
              <div className="xgs-shipment-details__grid__double-column-wrapper">
                <InfoBlock title="POD" className="xgs-shipment-details__grid__double-column">
                  <div className="xgs-shipment-details__grid__double-column__grid">
                    {shipmentDetailsState.shipment.pod && <>
                      <div style={{ marginRight: 24 }}>
                        <LabledValue
                          label="POD:"
                          value={
                            shipmentDetailsState.shipment.pod.date
                              ? `${shipmentDetailsState.shipment.pod.date.toUiDateFormat()} ${
                                  shipmentDetailsState.shipment.pod.time
                                    ? shipmentDetailsState.shipment.pod.time
                                    : ""
                                }`
                              : ""
                          }
                          labelFixWidth
                        />
                        <LabledValue
                          label="By:"
                          value={shipmentDetailsState.shipment.pod.signature || ""}
                          labelFixWidth
                        />
                      </div>
                      {shipmentDetailsState.shipment.fullAccess && (
                        <div>
                          <LabledValue
                            label="POD Entered:"
                            value={
                              shipmentDetailsState.shipment.pod.enteredDate
                                ? `${shipmentDetailsState.shipment.pod.enteredDate.toUiDateFormat()} ${
                                    shipmentDetailsState.shipment.pod.enteredTime
                                      ? shipmentDetailsState.shipment.pod.enteredTime
                                      : ""
                                  }`
                                : ""
                            }
                            labelFixWidth
                          />
                          <LabledValue
                            label="By:"
                            value={shipmentDetailsState.shipment.pod.enteredBy || ""}
                            labelFixWidth
                          />
                        </div>
                      )}
                    </>}   
                    {!shipmentDetailsState?.shipment?.pod && (
                      <div className="xgs-shipment-details__no-pod">
                        <span>No POD info</span>
                        {shouldShowPodButton && <Link to={`${xgsRoutes.shipments.enterPodInformation}?${PodSearchParams.selectedInvoices}=${invoiceNumber}`}><Button theme={ButtonThemes.blue}>Enter POD</Button></Link>}
                      </div>
                    )}
                  </div>
                </InfoBlock>
              </div>
            </InfoGrid>
            <InvoiceDetailsInfo {...shipmentDetailsState?.shipment} />
            {shipmentDetailsState.shipment?.fullAccess && (
              <InvoiceDetailsTable
                items={shipmentDetailsState.shipment?.items}
                invoiceAmount={shipmentDetailsState.shipment?.freightCharges}
              />
            )}
            {invoiceNumber && <InvoiceDetailsDocuments invoiceNumber={invoiceNumber} />}
            {shipmentDetailsState.shipment?.fullAccess && (
              <div className="xgs-shipment-details__tabs">
                <Tabs>
                  <TabList>
                    <Tab>Comments <AddShipmentComment invoiceNumber={invoiceNumber} /></Tab>
                    <Tab>Status History</Tab>
                    <Tab>Appt History</Tab>
                    <Tab>Exceptions History</Tab>
                  </TabList>
                  <TabPanel>
                    {shipmentDetailsState.shipment?.notes && (
                      <>
                        {shipmentDetailsState.shipment?.notes?.map((note, i) => (
                          <ShipmentComment comment={note} key={`shipment-note-${i}`} />
                        ))}
                      </>
                    )}
                    {!shipmentDetailsState.shipment?.notes && (
                      <div className="xgs-shipment-details__tabs__no-data">There are no comments.</div>
                      )}
                  </TabPanel>
                  <TabPanel>
                    <ShipmentStatusHistory invoiceNumber={invoiceNumber} />
                  </TabPanel>
                  <TabPanel>
                    <div className="xgs-shipment-details__tabs__appt-history">
                      <Table
                        data={freightDetailsState.details?.appointmentHistory}
                        columns={getAppointmentHistoryColumns()}
                        cursorPointer={false}
                        infiniteScroll={true}
                        minTableHeight={calcMinTableHeight(freightDetailsState.details?.appointmentHistory.length)}
                        strictMinTableHeight
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="xgs-shipment-details__tabs__exception-history">
                      <Table
                        columns={getFreightExceptionHistoryColumns()}
                        data={freightDetailsState.details?.freightExceptions}
                        infiniteScroll={true}
                        minTableHeight={calcMinTableHeight(freightDetailsState.details?.freightExceptions.length)}
                        strictMinTableHeight
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </div>
        )}
      </FitToWindowContainer>
    </ContentContainer>
  );
};
