export default interface ResetPasswordState {
  checkStarted: boolean;
  checkSucceed: boolean;
  checkFailed: boolean;
  checkError?: string | null;
  email: string;
  submitStarted: boolean;
  submitSucceed: boolean;
  submitFailed: boolean;
  submitError?: string | null;
};

export const initialResetPasswordState: ResetPasswordState = {
  checkStarted: false,
  checkSucceed: false,
  checkFailed: false,
  checkError: null,
  email: "",
  submitStarted: false,
  submitSucceed: false,
  submitFailed: false,
  submitError: null
};