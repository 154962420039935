import React, { useEffect } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/storeHooks";
import Button, { ButtonThemes } from "../../../components/button/button";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import XGSErrorMessage from "../../../components/error-message/errorMessage";
import Loading from "../../../components/loading/loading";
import Exclamation, {
  ExclamationTypes,
} from "../../../components/molecules/exclamation/exclamation";
import {
  ResetPasswordRequestModel,
  ResetPasswordSchema
} from "../../../app/data/user/requestModels";
import ResetPasswordState from "../../../slices/reset-password/ResetPasswordState";
import {
  checkResetPasswordToken,
  resetPasswordSelector,
  submitResetPassword
} from "../../../slices/reset-password/resetPasswordSlice";
import { ResetPasswordPath } from "./route";
import "./resetPassword.scss";

const initialValues: ResetPasswordRequestModel = {
  password: "",
  confirmPassword: "",
  token: "",
};

const ResetPassword: React.FC = () => {
  const resetPasswordState: ResetPasswordState = useSelector(resetPasswordSelector);
  const dispatch = useAppDispatch();
  const params = useParams();
  const token = params[ResetPasswordPath.token] || "";

  useEffect(() => {
    if (!token) return;
    initialValues.token = token;
    dispatch(checkResetPasswordToken(token));
  }, [dispatch, token]);

  const onSubmit = async (data: ResetPasswordRequestModel) => {
    dispatch(submitResetPassword(data));
  };

  return (
    <div className="xgs-login">
      <div className="xgs-login__wrapper">
        <div className="xgs-login__content">
          <div className="xgs-login__title">
            <span>Set New Password</span>
          </div>
          <Loading isLoading={resetPasswordState.submitStarted} />
          {(!token || resetPasswordState.submitFailed) && (
            <XGSErrorMessage className="xgs-reset-password-failed-message">
              {resetPasswordState.submitFailed ? resetPasswordState.submitError : "Error! Your password reset link is not working or has expired."}
            </XGSErrorMessage>
          )}
          {token && !resetPasswordState.submitSucceed && (
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={ResetPasswordSchema}
              >
                {(props: FormikProps<ResetPasswordRequestModel>) => (
                  <Form className="form-items">
                    <input type="hidden" name="token" />
                    <XGSFormInput
                      type="password"
                      name="password"
                      label="New password:"
                      required={true}
                      labelMode={LabelModes.column}
                    />
                    <XGSFormInput
                      type="password"
                      name="confirmPassword"
                      label="Repeat password:"
                      required={true}
                      labelMode={LabelModes.column}
                    />
                    <Button
                      theme={ButtonThemes.blue}
                      type="submit"
                      disabled={resetPasswordState.submitStarted}
                      className="xgs-login__button"
                    >
                      Save new password
                    </Button>
                  </Form>
                )}
              </Formik>
            )
          }
          {resetPasswordState.submitSucceed && (
            <div className="xgs-reset-password__result">
              <Exclamation type={ExclamationTypes.success}>
                The password for {resetPasswordState.email} was successfully changed.
              </Exclamation>
              <div className="xgs-site__box__link">Go to <a href="/" className="blue-link">Log In</a></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
