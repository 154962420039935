import React, { useEffect, useMemo } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import "./breadcrumbs.scss";
import { ShipmentDetailsPath } from "../../features/shipments/shipmentDetails/route";
import { AddFreightExceptionPaths } from "../../features/exceptions/route";
import { LiveFreightPath } from "../../features/exact-freight/route";

const isLastCrumb = (breadcrumbs: any, index: number) => {
  return index === breadcrumbs.length - 1;
};

const breadcrumbsRoutes = [
  {
    path: xgsRoutes.home,
    breadcrumb: "Home"
  },
  {
    path: xgsRoutes.shipments.tracking,
    breadcrumb: "Track Shipments"
  },
  {
    path: xgsRoutes.shipments.deliveryReceipts,
    breadcrumb: "Delivery receipts"
  },
  {
    path: `${xgsRoutes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`,
    breadcrumb: "Shipment Details"
  },
  {
    path: xgsRoutes.inboundFreight.listing,
    breadcrumb: "Inbound Freight"
  },
  {
    path: xgsRoutes.inboundFreight.updateLoadManifest,
    breadcrumb: "Update Load Manifest"
  },
  {
    path: xgsRoutes.inboundFreight.rollDetails,
    breadcrumb: "Roll Details"
  },
  {
    path: xgsRoutes.exactFreight.listing,
    breadcrumb: "Live Freight"
  },
  {
    path: `${xgsRoutes.exactFreight.listing}/:${LiveFreightPath.invoiceNumber}`,
    breadcrumb: "Shipment Details"
  },
  {
    path: xgsRoutes.freightExceptions.addFreightException,
    breadcrumb: "Freight Exceptions"
  },
  {
    path: `${xgsRoutes.freightExceptions.addFreightException}/:${AddFreightExceptionPaths.invoiceNumber}`,
    breadcrumb: "Add Freight Exception"
  },
  {
    path: xgsRoutes.shipments.podInformation,
    breadcrumb: "POD Information"
  },
  {
    path: `${xgsRoutes.shipments.enterPodInformation}`,
    breadcrumb: "Enter POD Information"
  },
  {
    path: `${xgsRoutes.freightAppointments.summary}`,
    breadcrumb: "Appointments"
  }
];

const PureBreadcrumbs = () => {
  const location = useLocation();
  useEffect(() => {}, []);
  const breadcrumbs = useMemo(() => {
    const filteredBreadcrumbs = breadcrumbsRoutes.filter(({ path, breadcrumb }) => {
      const pathSegments = location.pathname.split("/");
      let hasMatch = false;

      for (let idx = 0; idx < pathSegments.length; idx++) {
        const currentPath = pathSegments.slice(0, idx + 1).join("/");
        const match = matchPath(path, currentPath);

        if (match) {
          hasMatch = true;
          break;
        }
      }

      return hasMatch;
    });
    if (location.pathname !== "/") {
      filteredBreadcrumbs.unshift(breadcrumbsRoutes[0]);
    }

    document.title = filteredBreadcrumbs[filteredBreadcrumbs.length - 1].breadcrumb + " | XGS Agents";

    return filteredBreadcrumbs;
  }, [location]);

  return (
    <div className="xgs-breadcrumbs">
      {breadcrumbs.map(({ breadcrumb, path }: any, index: number) => (
        <div key={path}>
          {!isLastCrumb(breadcrumbs, index) ? (
            <>
              <Link to={path || "/home"} className="xgs-breadcrumbs__item">
                {breadcrumb}
              </Link>
              <span className="xgs-breadcrumbs__separator">&gt;</span>
            </>
          ) : (
            <>{breadcrumb}</>
          )}
        </div>
      ))}
    </div>
  );
};

export default PureBreadcrumbs;
