import React, { useCallback, useEffect, useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import { useSelector } from "react-redux";
import { resetTrackShipment, trackShipmentSelector } from "../../slices/track-shipment/trackShipmentSlice";
import TrackShipmentState from "../../slices/track-shipment/TrackShipmentState";
import Table from "../../components/table/table";
import { searchShipments } from "../../slices/track-shipment/trackShipmentSlice";
import getPODInformationColumns, { MaxPodRowsSelectable } from "./getPodInformationColumns";
import { useAppDispatch } from "../../hooks/storeHooks";
import "./podInformation.scss";
import { useNavigate } from "react-router";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import { ShipmentStatuses } from "../../app/data/common/shipmentStatuses";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import XGSFilter, { FilterConfig, FilterTypes } from "../../components/filter/filter";
import SearchShipmentRequestModel from "../../app/data/tracking/SearchShipmentRequestModel";
import Button, { ButtonThemes } from "../../components/button/button";
import { PodSearchParams } from "./route";
import { Link } from "react-router-dom";

const filterConfig: FilterConfig[] = [
  {
    label: "Probill Number",
    type: FilterTypes.number,
    key: "probillNumber",
    placeholderText: "Probill Number"
  },
  {
    label: "Masterbill Number",
    type: FilterTypes.number,
    key: "masterbill",
    placeholderText: "Masterbill Number"
  }
];

const PODInformationSummary: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const probillsState: TrackShipmentState = useSelector(trackShipmentSelector);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const navigate = useNavigate();
  
  const onClickSelectRow = (invoiceNumber: number) => {
    setSelectedRows((state) => {
      const set = new Set(state);
      if (set.has(invoiceNumber)) {
        set.delete(invoiceNumber);
      } else {
        set.add(invoiceNumber);
      }
      return set;
    });
  };

  const onClickSelectAllRows = (invoiceNumber: number) => {
    if (selectedRows.size){
      setSelectedRows(new Set());
    } else {
      setSelectedRows(() => {
        return new Set(probillsState.shipments.map(probill => probill.invoice.invoiceNumber).slice(0, MaxPodRowsSelectable))
      })
    }
  }

  const resetSelection = () => setSelectedRows(new Set());

  const onInfiniteScroll = () => {
    dispatch(searchShipments({ ...probillsState.request, shipmentGroup: ShipmentStatuses.podRequired }, true));
  };

  useEffect(() => {
    return () => {
      dispatch(resetTrackShipment());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  const buildEnterPodQueryString = () => {
    let queryString = `${PodSearchParams.selectedInvoices}=${Array.from(selectedRows).join(",")}`;
  
    const shouldIncludeAllDuplicates = !!(probillsState.request.masterbill && (probillsState.shipments.length <= MaxPodRowsSelectable));
    if (shouldIncludeAllDuplicates) {
      queryString += `&${PodSearchParams.allDuplicates}=yes`;
    }
  
    return queryString;
  };

  const onSubmit = () => {
    const queryString = buildEnterPodQueryString();
    navigate(`${xgsRoutes.shipments.enterPodInformation}?${queryString}`);
  };

  const onSearchMemo = useCallback((search: any) => {
    resetSelection();
    const searchRequest: SearchShipmentRequestModel = {
      trackingNumber: search.probillNumber ? [search.probillNumber.toString()] : [],
      masterbill: search.masterbill
    };
    dispatch(searchShipments({ ...searchRequest, shipmentGroup: ShipmentStatuses.podRequired }));
  }, [dispatch]);

  return (
    <ContentContainer
      header={<XGSFilter sameRowButtons filtersConfig={filterConfig} onClear={() => onSearchMemo({})} onSearch={onSearchMemo} />}
      title="POD Information"
      isLoading={probillsState.loading}
    >
      <div className="xgs-pod-information__buttons">
        <Button
          disabled={selectedRows.size === 0}
          onClick={onSubmit}
          className="xgs-pod-information__submit-button"
          theme={ButtonThemes.blue}
        >
          Continue
        </Button>
        <Link to={`${xgsRoutes.shipments.enterPodInformation}`}>
          Enter PODs without selecting probills
        </Link>
      </div>
      {!probillsState.loadingFailed && (
        <Table
          isLoading={probillsState.loading}
          columns={getPODInformationColumns(onClickSelectRow, onClickSelectAllRows, selectedRows)}
          data={probillsState.shipments}
          cursorPointer={false}
          infiniteScroll={true}
          infiniteScrollLoading={probillsState.loadingPortion}
          infiniteScrollHasNext={!probillsState.loadedAll}
          onInfiniteScroll={onInfiniteScroll}
          rowHeight={82}
          minTableHeight={420}
        />
      )}
      {probillsState.loadingError && <XGSErrorMessage>{probillsState.loadingError}</XGSErrorMessage>}
    </ContentContainer>
  );
};

export default PODInformationSummary;
