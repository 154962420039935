import React from "react";
import XGSIcons from "../../icon/xgsIcons";
import XGSIcon from "../../icon/xgsIcon";
import "./exclamation.scss";

export enum ExclamationTypes {
  success = "success",
  warning = "warning",
  error = "error",
  info = "info"
};

export interface ExclamationProps {
  type: ExclamationTypes;
  children: any;
};

const Exclamation: React.FC<ExclamationProps> = (props) => {
  const getIcon = (): XGSIcons.IconDefinition => {
    let icon: XGSIcons.IconDefinition = XGSIcons.faExclamationTriangle;
    switch (props.type) {
      case ExclamationTypes.error:
        icon = XGSIcons.faExclamationTriangle;
        break;
      case ExclamationTypes.success:
        icon = XGSIcons.faCheckCircle;
        break;
      case ExclamationTypes.warning:
        icon = XGSIcons.faExclamationCircle;
        break;
      case ExclamationTypes.info:
        icon = XGSIcons.faInfoCircle;
        break;
    };
    return icon;
  };

  return (
    <div className={`xgs-exclamation ${props.type}`}>
      <div className="xgs-exclamation__icon-container">
        <XGSIcon
          icon={getIcon()}
          className="xgs-exclamation__icon"
        />
      </div>
      <div className="xgs-exclamation__message">{props.children}</div>
    </div>
  );
};

export default Exclamation;
